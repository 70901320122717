import { useRecoilValue, useRecoilState } from 'recoil';
import { gql, useMutation, useApolloClient } from '@apollo/client';
import { lastLockedOrder, getLockedBy } from './atoms';
import React from 'react';
import { useRouter } from 'next/router';
import useConfirmationDialog from 'core/hooks/dialogs/useConfirmationDialog';

export const QUERY_GET_ORDER_LOCK = gql`
  query getOrderLock($id: ID!){
    getOrderLock(id: $id) {
      id
      lockedBy
      createdAt
      updatedAt
      lockDuration
    }
  }
`;

const MUTATION_UPDATE_ORDER_LOCK = gql`
  mutation updateOrderLock($id: ID!, $lockedBy: String) {
    updateOrderLock(id: $id, data: {id: $id, lockedBy: $lockedBy}){
      id
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

const MUTATION_CREATE_ORDER_LOCK = gql`
  mutation createOrderLock($id: ID!, $lockedBy: String) {
    createOrderLock(data: {id: $id, lockedBy: $lockedBy}){
      id
      lockedBy
      createdAt
      updatedAt
    }
  }
`;

const MUTATION_REMOVE_ORDER_LOCK = gql`
  mutation removeOrderLock($id: ID!) {
    removeOrderLock(id: $id)
  }
`;

const intervalTime = 5000;

const delay = async (s) => new Promise((r) => setTimeout(r, s * 1000));

export const useOrderLock = (_orderId, backPath, refetch) => {
  const userLockedBy = useRecoilValue(getLockedBy);
  const [lockedOrder, setLockedOrder] = useRecoilState(lastLockedOrder);
  const router = useRouter();
  const { query: { orderId } } = router;
  const client = useApolloClient();
  const [dialogConfig, setDialogConfig] = React.useState({});

  const { show, hide } = useConfirmationDialog({
    width: '312px',
    title: 'Warning',
    ...dialogConfig
  });

  const [updateOrderLock] = useMutation(MUTATION_UPDATE_ORDER_LOCK);
  const [createOrderLock] = useMutation(MUTATION_CREATE_ORDER_LOCK);
  const [removeOrderLock] = useMutation(MUTATION_REMOVE_ORDER_LOCK);

  React.useEffect(() => {
    let timer = null;
    const getLockData = async (id) => {
      const resp = await client.query({ query: QUERY_GET_ORDER_LOCK, variables: { id }, fetchPolicy: 'network-only' });
      // console.log('---resp---', id, resp.data?.getOrderLock);
      return resp.data?.getOrderLock ?? { lockedBy: null };
    }
    const fn = async () => {
      if (orderId && userLockedBy) {
        const { lockedBy } = await getLockData(orderId);
        // console.log('---debug---', lockedBy, lockedOrder, orderId);
        if (lockedBy && lockedBy !== userLockedBy && lockedOrder === orderId) {
          clearInterval(timer);
          const [username] = (lockedBy || '').split('::');
          setDialogConfig({
            question: (
              <p>
                <b>{username}</b> has taken over this order
              </p>
            ),
            confirmText: 'Ok',
            cancelText: undefined,
            onConfirm: async () => {
              setLockedOrder(null);
              router.push(backPath);
            },
          });
          return show();
        } else if (lockedBy && lockedBy !== userLockedBy && lockedOrder !== orderId) {
          clearInterval(timer);
          const [username] = lockedBy.split('::');
          setDialogConfig({
            question: (
              <p>
                <b>{username}</b> is accessing this order, Do you want to take over this order or go back
              </p>
            ),
            cancelText: 'Back',
            confirmText: 'Take Over',
            onConfirm: async () => {
              hide();
              await updateOrderLock({ variables: { id: orderId, lockedBy: userLockedBy } });
              setDialogConfig({
                title: 'Waiting',
                question: 'data syncing',
                isLoading: true,
                confirmText: undefined,
                cancelText: undefined
              });
              show();
              await delay(8);
              hide();
              if (refetch) {
                await refetch();
              }
              timer = setInterval(() => fn(), intervalTime);
            },
            onCancel: () => {
              setLockedOrder(null);
              router.push(backPath);
            },
          });
          return show();
        } else if (!lockedBy) {
          await createOrderLock({ variables: { id: orderId, lockedBy: userLockedBy } });
        } else {
          await updateOrderLock({ variables: { id: orderId, lockedBy: userLockedBy } });
        }
        if (lockedOrder && lockedOrder !== orderId) {
          const { lockedBy: prevOrderLockedBy } = await getLockData(lockedOrder);
          if (prevOrderLockedBy === userLockedBy) {
            await removeOrderLock({ variables: { id: lockedOrder } });
          }
        }
        if (lockedOrder !== orderId) {
          setLockedOrder(orderId);
        }
      }
    }
    fn();
    timer = setInterval(() => fn(), intervalTime);
    // console.log('---lockedOrder---', lockedOrder, orderId);
    return () => clearInterval(timer);
  }, [backPath, client, createOrderLock, hide, lockedOrder, orderId, refetch, removeOrderLock, router, setLockedOrder, show, updateOrderLock, userLockedBy]);

  return null;
}

export const useOrderUnlock = () => {
  const [lockedOrder, setLockedOrder] = useRecoilState(lastLockedOrder);
  const [removeOrderLock] = useMutation(MUTATION_REMOVE_ORDER_LOCK);
  const userLockedBy = useRecoilValue(getLockedBy);
  const client = useApolloClient();

  React.useEffect(() => {
    const getLockData = async (id) => {
      const resp = await client.query({ query: QUERY_GET_ORDER_LOCK, variables: { id }, fetchPolicy: 'network-only' });
      return resp.data?.getOrderLock ?? { lockedBy: null };
    }
    const fn = async () => {
      if (lockedOrder) {
        const { lockedBy } = await getLockData(lockedOrder);
        if (lockedBy === userLockedBy) {
          await removeOrderLock({ variables: { id: lockedOrder } });
        }
        setLockedOrder(null);
      }
    }
    fn();
  }, [removeOrderLock, lockedOrder, setLockedOrder, client, userLockedBy]);
  return null;
}