import styled from 'styled-components/macro';

const Overlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 30px 0;
`;

export const Container = styled.div`
  background: #fff;
  border-radius: 3px;
  padding: 30px;
  width: ${({width}) => width || 'auto'};
  position: relative;
`;

const Header = styled.div`
  font-weight: 500;
  font-size: 22.624px;
`;

const Content = styled.div``;

const Footer = styled.div`
  margin-top: 50px;
`;

const Dialog = ({className, children, header, footer, width}) => {
  return (
    <div className={className}>
      <Overlay />
      <Wrapper>
        <Container width={width}>
          {header && <Header>{header}</Header>}
          <Content>{children}</Content>
          {footer && <Footer>{footer}</Footer>}
        </Container>
      </Wrapper>
    </div>
  );
};

export default styled(Dialog)`
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  font-size: 16px;
  overflow: auto;
`;
