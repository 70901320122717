import styled from 'styled-components/macro';
import StyledSpinner from 'core/components/Spinner';

const SpinnerOverlay = ({...props}) => (
  <div {...props}>
    <StyledSpinner />
  </div>
);

export default styled(SpinnerOverlay)`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;
