import React from 'react';
import styled, { css } from 'styled-components/macro';
import Spinner from './Spinner';

export const Spacing = styled.div`
  height: ${({ spacingSize }) => spacingSize};
`;

const Button = ({
  className,
  children,
  loading: pLoading = false,
  disabled: pDisabled = false,
  onClick,
  spacingSize = '80px',
  sticky,
  onClickDelay = 0
}) => {
  const [counter, setCounter] = React.useState(0);

  const disabled = React.useMemo(() => {
    if (!onClickDelay) {
      return pDisabled;
    }
    if (counter) {
      return true;
    }
  }, [onClickDelay, counter, pDisabled]);

  const loading = React.useMemo(() => {
    if (!onClickDelay) {
      return pLoading;
    }
    if (counter) {
      return true;
    }
  }, [onClickDelay, counter, pLoading])


  const delayFn = (num) => {
    if (num > 0) {
      setTimeout(() => {
        delayFn(num - 1);
        setCounter(num - 1);
      }, 1000);
    } else {
      setCounter(0);
    }

  }

  const handleClick = e => {
    if (!disabled && onClick) {
      onClick(e);
      setCounter(onClickDelay);
      delayFn(onClickDelay);
    }
  };

  const spinnerText = onClickDelay > 0 ? <span>{counter}</span> : null;

  const customStyle = {
    'backgroundColor': disabled ? '#e1e3e5' : '#0a6629'
  }

  return (
    <>
      {sticky && <Spacing spacingSize={spacingSize} />}
      <button className={className} onClick={handleClick} style={customStyle}>
        {
          loading &&
          <div style={{ color: 'black', fontSize: 14, position: 'absolute' }}>{spinnerText}</div>
        }

        {loading ? <Spinner size="small">{spinnerText}</Spinner> : children}
      </button>
    </>
  );
};

{/* <div style={{ color: 'black', fontSize: 14, margin: '0 auto' }}>{spinnerText}</div> */ }

export default styled(Button)`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #fff;
  border-radius: ${({ round }) => (round ? '50%' : '10px')};
  cursor: pointer;
  border: none;
  font-size: 20px;
  padding: 17px;

  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};

  ${({ sticky }) =>
    sticky &&
    css`
      position: fixed;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
    `}

  background-color: ${({ disabled }) => (disabled ? '#e1e3e5' : '#0a6629')};
`;
