import styled from 'styled-components/macro';
import { useModal } from 'react-modal-hook';
import Dialog from 'core/components/Dialog';
import React, { useRef } from 'react';
import SpinnerOverlay from 'core/components/SpinnerOverlay';

const Question = styled.div`
  color: #696969;
  font-size: 19px;
  line-height: 27px;
  font-weight: 300;
  margin: 10px 0;
`;

const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Button = styled.button`
  cursor: pointer;
  color: #0a6629;
  text-transform: uppercase;
  background: none;
  outline: none;
  border: none;
  font-size: 16px;
`;

export default function useConfirmationDialog({
  width,
  title,
  question,
  confirmText,
  cancelText,
  onConfirm,
  isLoading,
  onCancel,
  resetText,
  onReset,
  showReset
}) {
  const dataRef = useRef(null);

  const handleConfirm = () => {
    onConfirm(dataRef.current);
  };

  const handleCancel = () => {
    onCancel(dataRef.current);
  };

  const handleReset = () => {
    onReset(dataRef.current);
  };

  const [show, hide] = useModal(() => {
    return (
      <Dialog
        width={width}
        header={title}
        footer={
          <Actions>
            {
              showReset && <Button onClick={handleReset}>{resetText || 'Reset'}</Button>
            }
            {
              cancelText !== undefined && <Button onClick={handleCancel}>{cancelText || 'Cancel'}</Button>
            }
            {
              confirmText !== undefined && <Button onClick={handleConfirm}>{confirmText || 'Confirm'}</Button>
            }

          </Actions>
        }
      >
        <Question>{question}</Question>
        {isLoading && <SpinnerOverlay />}
      </Dialog>
    );
  }, [onCancel, handleConfirm]);

  const handleShow = React.useCallback(data => {
    dataRef.current = data;
    show();
  }, [show]);

  return { show: handleShow, hide };
}
